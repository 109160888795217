// Import everything from autoload folder
import './autoload/biskoui.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

var ajax_object = ajax_object || {};

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  $('.preview--post').matchHeight({
    byRow: true,
  });

  $('.preview--press').matchHeight();
  $('.preview--press_kit').matchHeight();

  var closeButtonForm = $('.close-form-button');
  var form = $('.popup-form-wrap');
  var openButtonForm = $('.open-form-button');

  openButtonForm.hover(function () {
    form.addClass('show');
  });
  closeButtonForm.click(function () {
    form.removeClass('show');
  });

  $('.gallery-slider').each(function () {
    $(this).slick({
      dots: true,
      infinite: true,
      speed: 500,
      fade: true,
      cssEase: 'linear',
      appendDots: $(this).closest('.text-image').find('.gallery-slider__dots'),
      prevArrow: $(this).closest('.text-image').find('.gallery-slider__prev'),
      nextArrow: $(this).closest('.text-image').find('.gallery-slider__next'),
    });
  });

  $('.media-slider').slick({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('.media-slider__prev'),
    nextArrow: $('.media-slider__next'),
    appendDots: $('.media-slider__dots'),
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('#play').on('click', function (ev) {
    $(this).css({
      'z-index': '-1',
    });

    $('iframe').css('z-index', '2');
    $('iframe')[0].src += '&autoplay=1';
    ev.preventDefault();
  });

  $('.series__leng-flag').on('click', function () {
    var url = $(this).attr('data-place');
    var block = $(this).parents('.series__leng');
    var items = block.find('.series__leng-link');
    items.each(function () {
      $(this).removeClass('link-active');
      if ($(this).hasClass(url)) {
        $(this).addClass('link-active');
      }
    });
    var flag = block.find('.series__leng-flag');
    flag.each(function () {
      $(this).removeClass('active');
    });
    $(this).addClass('active');
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  $('.post-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    rows: 0,
    prevArrow: $('.post-slider__prev'),
    nextArrow: $('.post-slider__next'),
    appendDots: $('.post-slider__dots'),
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // post slider section
  $('.single-post-slider').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    centerPadding: '80px',
    prevArrow: $('.post-slider__prev'),
    nextArrow: $('.post-slider__next'),
    appendDots: $('.post-slider__dots'),
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
    ],
  });

  //recent activities slider
  $('.recent-activities__slider-wrapp').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    prevArrow: $('.recent-activities__prev'),
    nextArrow: $('.activities-list__next'),
    appendDots: $('.recent-activities__dots'),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
    ],
  });

  // ACF Google Map JS code

  /*
   *  This function will render a Google Map onto the selected jQuery element
   *
   */

  /* global google */

  function render_map($el) {
    // var
    var $markers = $el.find('.marker');

    // vars
    var args = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      // styles : styles // Uncomment for map styling
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function () {
      add_marker($(this), map);
    });

    // center map
    center_map(map);
  }

  /*
   *  This function will add a marker to the selected Google Map
   */

  var infowindow;
  function add_marker($marker, map) {
    // var
    var latlng = new google.maps.LatLng(
      $marker.attr('data-lat'),
      $marker.attr('data-lng')
    );

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($.trim($marker.html())) {
      // create info window
      infowindow = new google.maps.InfoWindow();

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function () {
        // Close previously opened infowindow, fill with new content and open it
        infowindow.close();
        infowindow.setContent($marker.html());
        infowindow.open(map, marker);
      });
    }
  }

  /*
   *  This function will center the map, showing all markers attached to this map
   */

  function center_map(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function (i, marker) {
      var latlng = new google.maps.LatLng(
        marker.position.lat(),
        marker.position.lng()
      );
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (map.markers.length == 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  /*
   *  This function will render each map when the document is ready (page has loaded)
   */

  $('.acf-map').each(function () {
    render_map($(this));
  });

  // Filter Post
  $('#filter').change(function () {
    var filter = $('#filter');
    let $container = $('.posts-list');
    let $pagination = $('.pagination-wrapper');
    let $cat = $('select[name=categoryfilter]').val();
    $.ajax({
      url: filter.attr('action'),
      data: filter.serialize(), // form data
      type: filter.attr('method'), // POST
      success: function (data) {
        $('#posts-list').html(data); // insert data
        $('#posts-list').attr('data-paged', 1);
        if ($container.find('article').length < 8) {
          $('.js-load-post').hide();
        } else {
          $('.js-load-post').show();
        }
        if ($cat == '') {
          $pagination.show();
          $('.js-load-post').hide();
        } else {
          $pagination.hide();
        }
      },
    });
    return false;
  });

  // Filter Press
  $('#filter-press').change(function () {
    var filter = $('#filter-press');
    let $container = $('.js-load-press').siblings('.posts-list');
    $.ajax({
      url: filter.attr('action'),
      data: filter.serialize(), // form data
      type: filter.attr('method'), // POST
      success: function (data) {
        $('#press-list').html(data); // insert data
        if ($container.find('article').length < 9) {
          $('.js-load-press').hide();
        } else {
          $('.js-load-press').show();
        }
        if ($container.find('article').length < 8) {
          $('.js-load-press-kit').hide();
        } else {
          $('.js-load-press-kit').show();
        }
      },
    });
    return false;
  });

  // Load more press
  if ($('.posts-list').length) {
    $('.js-load-press').on('click', function () {
      let $this = $(this);
      let $container = $this.siblings('.posts-list');
      let $form = $this.siblings('.post-filter-wrap').find('#filter-press');
      let $formAction = $form.attr('action');
      $this.attr('action', $formAction);

      // eslint-disable-next-line no-undef
      $.post(ajax_object.ajax_url, {
        action: 'load_more',
        offset: $container.find('article').length,
        to_show: $this.data('show'),
        current_id: $form.find(':selected').val(),
      }).done(function (response) {
        if (response.html) {
          $container.append(response.html);
        }
        if ($container.find('article').length >= response.post_count) {
          $this.hide();
        }
      });
    });
  }

  // Load more kit press
  if ($('.posts-list').length) {
    let enableAjax = true;
    $('.js-load-post').on('click', function () {
      let $this = $(this);
      let $container = $this.siblings('.posts-list');
      let $form = $this.siblings('.post-filter-wrap').find('#filter');
      if (enableAjax) {
        enableAjax = false;
        $.post(ajax_object.ajax_url, {
          action: 'myfilter_load',
          paged: $('.posts-list').attr('data-paged'),
          categoryfilter: $form
            .find('select[name="categoryfilter"] option')
            .filter(':selected')
            .val(),
          date: $form
            .find('select[name=date] option')
            .filter(':selected')
            .val(),
        }).done(function (response) {
          if (response.posts) {
            $container.append(response.posts);
            $container.attr('data-paged', response.paged);
          }
          if (response.paged == response.max_num_pages) {
            $this.hide();
          }
          enableAjax = true;
        });
      }
    });
  }

  // Load more kit press
  if ($('.posts-list').length) {
    let enableAjax = true;
    $('.js-load-post').on('click', function () {
      let $this = $(this);
      let $container = $this.siblings('.posts-list');
      let $form = $this.siblings('.post-filter-wrap').find('#filter');
      if (enableAjax) {
        enableAjax = false;
        $.post(ajax_object.ajax_url, {
          action: 'myfilter_load',
          paged: $('.posts-list').attr('data-paged'),
          categoryfilter: $form
            .find('select[name=categoryfilter] option')
            .filter(':selected')
            .val(),
          date: $form
            .find('select[name=date] option')
            .filter(':selected')
            .val(),
        }).done(function (response) {
          if (response.posts) {
            $container.append(response.posts);
            $container.attr('data-paged', response.paged);
          }
          if (response.paged == response.max_num_pages) {
            $this.hide();
          }
          enableAjax = true;
        });
      }
    });
  }
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  resizeVideo();
});

$(window).on('load resize', function () {
  if ($(window).width() < 640) {
    $('.activities-post__slider-wrapp:not(.slick-initialized)').slick({
      dots: true,
      infinite: true,
      slidesToShow: 1,
      prevArrow: $('.activities-post__prev'),
      nextArrow: $('.activities-post__next'),
      appendDots: $('.activities-post__dots'),
    });
    $('.activities-list__slider-wrapp:not(.slick-initialized)').slick({
      dots: true,
      infinite: true,
      slidesToShow: 1,
      prevArrow: $('.activities-list__prev'),
      nextArrow: $('.activities-list__next'),
      appendDots: $('.activities-list__dots'),
    });
  } else {
    $('.activities-post__slider-wrapp.slick-initialized').slick('unslick');
    $('.activities-list__slider-wrapp.slick-initialized').slick('unslick');
  }
});
